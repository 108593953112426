<template>
  <div
    class="collapse"
    :class="{
      [`collapse_${direction}`]: true,
      collapse_show: cIsFullVisible || show,
    }"
  >
    <div
      class="collapse__content-wrap"
      :style="cStyle"
    >
      <div
        ref="content"
        class="collapse__content"
      >
        <slot name="content" />
        <div
          v-if="fadeColor"
          class="collapse__fade"
          :style="cFadeStyle"
        />
      </div>
    </div>
    <div
      v-if="cShowToggle"
      class="collapse__toggle"
      :class="'caret-' + caretPosition"
      v-bind="$attrs"
      @click.stop.prevent="toggle"
    >
      <div class="collapse__toggle__title">
        <slot
          name="title"
          :is-show="show"
        />
      </div>
      <template v-if="!disabled && caret">
        <svg
          v-if="caretType === 'plus'"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#5047E6"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="lucide lucide-plus-icon collapse-plus"
          color="#5047E6"
        >
          <path d="M5 12h14" />
          <path d="M12 5v14" />
        </svg>
        <ChevronUp
          v-else-if="caretType === 'chevron'"
          :width="20"
          :height="20"
          class="collapse-chevron"
        />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted } from 'vue';
import { ChevronUp } from 'lucide-vue-next';

const emit = defineEmits(['click', 'open', 'close']);

const props = defineProps({
  visibleHeight: {
    type: [Number, String],
    default: 0,
  },
  direction: {
    type: String,
    default: 'bottom',
  },
  initialOpen: {
    type: Boolean,
    default: false,
  },
  caret: {
    type: Boolean,
    default: true,
  },
  caretType: {
    type: String,
    default: 'plus',
  },
  alwaysShowToggle: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  fadeColor: {
    type: String,
    default: null,
  },
  caretPosition: {
    type: String,
    default: 'right',
  },
  isOpen: {
    type: Boolean,
    default: undefined,
  },
});
const show = props.isOpen != undefined ? computed(() => props.isOpen) : ref(props.initialOpen);
const contentHeight = ref(null);
const animationCompleted = ref(props.initialOpen);
const content = ref(null);

onMounted(() => {
  calculateHeight();
});

const cStyle = computed(() => {
  if (animationCompleted.value) {
    return { maxHeight: show.value ? 'unset' : props.visibleHeight + 'px' };
  } else {
    return { maxHeight: show.value ? contentHeight.value + 'px' : props.visibleHeight + 'px' };
  }
});
const cIsFullVisible = computed(() => {
  return props.visibleHeight >= contentHeight.value;
});
const cShowToggle = computed(() => {
  return props.alwaysShowToggle || !cIsFullVisible.value;
});
const cFadeStyle = computed(() => {
  return {
    background: `linear-gradient(0, ${props.fadeColor}, transparent)`,
  };
});

function calculateHeight() {
  contentHeight.value = content.value.scrollHeight;
}

function toggle() {
  emit('click');
  if (props.disabled) {
    return;
  }
  calculateHeight();
  animationCompleted.value = false;
  setTimeout(() => {
    if (show.value) {
      emit('close');
      if (props.isOpen === undefined) show.value = false;
    } else {
      emit('open');
      if (props.isOpen === undefined) show.value = true;
    }

    setTimeout(() => {
      animationCompleted.value = true;
    }, 300);
  }, 0);
}
</script>

<style scoped lang="scss">
.collapse-plus {
  path {
    transition: 0.3s;
    transform-origin: center;
    transform: rotate(0);
  }
}

.collapse-chevron {
  transition: 0.3s;
  transform-origin: center;
  transform: rotate(90deg);
  stroke: $new-fr-gray;
}

.collapse_show {
  .collapse__toggle {
    .collapse-plus {
      path:first-child {
        transform: rotate(180deg);
      }
      path:last-child {
        transform: rotate(90deg);
      }
    }

    .collapse-chevron {
      transform: rotate(180deg);
    }
  }
}

.collapse {
  display: flex;
  flex-direction: column;
}

.collapse_bottom {
  .collapse__toggle {
    order: -1;
  }
}

.collapse__content-wrap {
  overflow: hidden;
  transition: max-height 0.3s;
  position: relative;
}

.collapse__toggle {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;

  &.caret-left {
    flex-direction: row-reverse;
  }

  .collapse__toggle__title {
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 8px;
    justify-content: space-between;
  }
}

.collapse__caret {
  transition: transform 0.3s ease;
  display: inline-block;
  font-weight: 900;
  color: $dark;
  margin-left: 5px;
}

.collapse__caret_show {
  transform-origin: center;
  transform: rotate(180deg);
}

.collapse__fade {
  position: absolute;
  bottom: 0;
  height: 30px;
  width: 100%;
  pointer-events: none;
}

.collapse_show {
  .collapse__fade {
    opacity: 0;
  }
}
</style>
